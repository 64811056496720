import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`When first creating the 3D modeling team, we wanted to include more undergraduates in the project so we partnered with the university undergraduate research program and were lucky to find Brianna Kucharski, a student in the architecture program. After graphing out our calculations of the relative size of each model, based on Welles’s own sketches and notes, Brianna and I met with Dr. Theodore Hall and Stephanie O’Malley from the university’s `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.dc.umich.edu/spaces/visualization-spaces"}}>{`3D lab and XR Visualization Space`}</MDXTag>{` in order to figure out the best process for creating the virtual reality models. In the end, Brianna used a program called SketchUp to build the base of the models and lay textures and patterns on them so they appeared lifelike and true to Welles’s vision. Then we exported them into a virtual reality cave and were eventually able to create 3D tours of each model. We ran into a bit of difficulty making the models look like more than simple digital structures and struggled with finding proper textures to layer on the wood of the temple. Later in the process, we also ran into trouble when trying to convert each model into their own interactive 360 degree video, for the program we used seemed to have limited recording capabilities. After many trials and tribulations, the team was able to create videos that would allow users to explore the models from all sides, providing a 360 degree experience. `}</MDXTag>
<MDXTag name="p" components={components}>{`Sydney Simoncini, 2020`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"The Modeling Process","key":"the-modeling-process","parentKey":"3d-vr-models","sortOrder":0};

  